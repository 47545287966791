<template>
  <div>
    <div class="grid crud-demo">
      <div class="col-12">
        <div class="card">
          <h6 class="">Administração de Usuários</h6>
          <Toast/>
          <Toolbar class="mb-4">
            <template v-slot:start>
              <div class="my-2">
                <Button label="Novo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
                <!--              <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedUsuarios || !selectedUsuarios.length" />-->
              </div>
            </template>

            <template v-slot:end>
              <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Importar" chooseLabel="Importar" class="mr-2 p-button-outlined" />
              <Button label="Exportar" icon="pi pi-download" class="p-button-outlined" @click="exportCSV($event)"  />
            </template>
          </Toolbar>

          <DataTable ref="dt" :value="usuarios" v-model:selection="selectedUsuarios" dataKey="id" :paginator="true" :rows="10"
                     v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1"
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords} usuários" responsiveLayout="scroll">
            <template #header>
              <div class="flex justify-content-between flex-column sm:flex-row">
                <Button type="button" icon="pi pi-filter-slash" label="Limpar Filtros" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                <span class="p-input-icon-left mb-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filters1['global'].value" placeholder="Pesquisar..." style="width: 100%"/>
                  </span>
              </div>
            </template>


            <Column field="id" header="ID" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">code</span>
                {{slotProps.data.id}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo código"/>
              </template>
            </Column>



            <Column field="name" header="Nome" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Nome</span>
                {{slotProps.data.name}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo nome"/>
              </template>
            </Column>


            <Column field="cidade" header="Cidade" :sortable="true" class="text-left">
              <template #body="slotProps">
                <span class="p-column-title">Cidade</span>
                {{formatCurrency(slotProps.data.cidade)}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela cidade"/>
              </template>
            </Column>
            <Column field="estado" header="Estado" :sortable="true" class="text-right">
              <template #body="slotProps">
                <span class="p-column-title">Estado</span>
                {{slotProps.data.estado}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela estado"/>
              </template>
            </Column>

            <Column field="tipo" header="Perfil" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Perfil</span>
                {{slotProps.data.tipo}}
              </template>

              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo perfil"/>
              </template>

            </Column>

            <Column field="status" header="Status" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Status</span>
                <span :class="'usuario-badge status-'+slotProps.data.status">{{slotProps.data.status}}</span>
              </template>

              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo status"/>
              </template>

            </Column>

            <Column field="last_login" header="Último Login" :sortable="true" dataType="date" style="min-width:10rem">
              <template #body="slotProps">
                {{formatDate(slotProps.data.last_login)}}
              </template>
            </Column>

            <Column header="Ações" class="text-right">
              <template #body="slotProps">
                <!--                <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mr-2" @click="confirmDeleteUsuario(slotProps.data)" />-->
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editUsuario(slotProps.data)" />
                <!-- <Button icon="pi pi-sort-amount-up" class="p-button-rounded p-button-help mr-2" @click="visibleTimeline = true" /> -->
              </template>
            </Column>
          </DataTable>




        </div>
      </div>


    </div>
    <div class="grid p-fluid">
      <div class="col-12 lg:col-6">
        <div class="card flex flex-column align-items-center">
          <h6>Agrupamento Perfil</h6>
          <Chart type="doughnut" :data="pieData" :options="pieOptions" style="position:relative; width: 50%"></Chart>
        </div>
      </div>
      <div class="col-12 lg:col-6">
        <div class="card">
          <h6>Evolução Volume de Usuários</h6>
          <Chart type="line" :data="lineData" :options="lineOptions"></Chart>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import UsuarioService from '@/service/UsuarioService';
// import UsuariosListTimeline from "@/components_esol/usuarios/UsuariosListTimeline";


export default {
  components: {
    // UsuariosListTimeline

  },
  data() {
    return {
      statusList: [
          {name: true },
          {name: false },
      ],
      visibleTimeline: false,
      loading1: true,
      filters1: null,
      usuarios: null,
      usuario: {},
      selectedUsuarios: null,
      filters: {},
      submitted: false,
      statuses: [
        {label: 'QUALIFICADO', value: 'qualificado'},
        {label: 'ANÁLISE', value: 'análise'},
        {label: 'DESQUALIFICADO', value: 'desqualificado'}
      ],

      tipos: [
        {name: "admin" },
        {name: "super" },
        {name: "gernt" },
        {name: "repre" },
      ],

      categorias: [
        {name: "Rural" },
        {name: "Residencial" },
        {name: "Grandes Negócios" },
      ],


      barData: {
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho'],
        datasets: [
          {
            label: 'Elaboração',
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: [65, 59, 80, 81, 56, 55, 40]
          },
          {
            label: 'Convertido',
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
            data: [28, 48, 40, 19, 86, 27, 90]
          }
        ]
      },
      barOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
        }
      },


      radarData: {
        labels: ['GD1', 'GD0', 'MD1', 'MD0', 'PQ2', 'PQ1', 'PQ0'],
        datasets: [
          {
            label: 'ES',
            backgroundColor: 'rgba(54, 162, 235,0.2)',
            borderColor: 'rgba(54, 162, 235,1)',
            pointBackgroundColor: 'rgba(54, 162, 235,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(54, 162, 235,1)',
            data: [65, 59, 90, 81, 56, 55, 40]
          },
          {
            label: 'MG',
            backgroundColor: 'rgba(255, 99, 132,0.2)',
            borderColor: 'rgba(255, 99, 132,1)',
            pointBackgroundColor: 'rgba(255, 99, 132,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(255, 99, 132,1)',
            data: [28, 48, 40, 19, 96, 27, 100]
          }
        ]
      },

      radarOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          r: {
            grid: {
              color: 'rgba(160, 167, 181, .3)'
            }
          }
        }
      },


      pieData: [],
      pieOptions: [],


      lineData: [],
      lineOptions: [],

    }
  },
  usuarioService: null,
  created() {
    this.usuarioService = new UsuarioService()
    this.initFilters()
  },
  mounted() {


    this.usuarioService.getssusuarioindex().then((data) => {
      console.log('usuariosList mounted')


      if (data === 'nao_permitido') {
        this.$router.replace({ path: '/acessorestrito' })
      }else{

        this.loading1 = false
        this.usuarios = data[0]
        let labelsPieChart = []

        this.usuarios.forEach((usuario) => {
          usuario.last_login = new Date(usuario.last_login)
          labelsPieChart.push(usuario.tipo)
        })

        let labelsLinesChart = data[1]
        let valuesLines1Chart = data[2]
        let valuesLines2Chart = data[3]
        console.log('labelsLinesChart')
        //console.log(labelsLinesChart)
        console.log(valuesLines1Chart)

        this.lineData = {
          labels: labelsLinesChart,
          datasets: [
            {
              label: 'Novos Usuários',
              data: valuesLines1Chart,
              fill: false,
              backgroundColor: 'rgb(255, 205, 86)',
              borderColor: 'rgb(255, 205, 86)',
              tension: .4
            },
            {
              label: 'Último Login',
              data: valuesLines2Chart,
              fill: false,
              backgroundColor: 'rgb(75, 192, 192)',
              borderColor: 'rgb(75, 192, 192)',
              tension: .4
            }
          ]
        }

        this.lineOptions = {
          plugins: {
            legend: {
              labels: {
                color: '#A0A7B5'
              }
            }
          },
          scales: {
            x: {
              ticks: {
                color: '#A0A7B5'
              },
              grid: {
                color:  'rgba(160, 167, 181, .3)',
              }
            },
            y: {
              ticks: {
                color: '#A0A7B5'
              },
              grid: {
                color:  'rgba(160, 167, 181, .3)',
              }
            },
          }
        }

        labelsPieChart = [...new Set(labelsPieChart)]
        let valuesPieData = []
        labelsPieChart.forEach((label) => {
          // console.log(label)
          let totalLabel =  this.usuarios.filter(x => x.tipo === label).length;
          valuesPieData.push(totalLabel)
          // console.log('totalLabel')
          // console.log(totalLabel)
        })

        // console.log('labelsPieChart')
        // console.log(labelsPieChart)
        // console.log('valuesPieData')
        // console.log(valuesPieData)
        this.pieData = {
          labels: labelsPieChart,
          datasets: [
            {
              data: valuesPieData,
              backgroundColor: [
                'rgb(54, 162, 235)',
                'rgb(255, 99, 132)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)'
              ]
            }]
        },
            this.pieOptions = {
              plugins: {
                legend: {
                  labels: {
                    color: '#A0A7B5'
                  }
                }
              }
            }

      }
    })


  },
  methods: {
    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return;
    },
    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return;
    },
    openNew() {
      this.$router.push({ path: '/usuariodetalhe/0' });
    },
    hideDialog() {
      this.usuarioDialog = false;
      this.submitted = false;
    },
    saveUsuario() {
      this.submitted = true;
      if (this.usuario.name.trim()) {
        if (this.usuario.id) {
          this.usuario.inventoryStatus = this.usuario.inventoryStatus.value ? this.usuario.inventoryStatus.value: this.usuario.inventoryStatus;
          this.usuarios[this.findIndexById(this.usuario.id)] = this.usuario;
          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Usuário Atualizado', life: 3000});
        }
        else {
          this.usuario.id = this.createId();
          this.usuario.code = this.createId();
          this.usuario.image = 'usuario-placeholder.svg';
          this.usuario.inventoryStatus = this.usuario.inventoryStatus ? this.usuario.inventoryStatus.value : 'ANÁLISE';
          this.usuarios.push(this.usuario);
          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Usuário Criado', life: 3000});
        }
        this.usuarioDialog = false;
        this.usuario = {};
      }
    },
    editUsuario(usuario) {
      this.$router.push({ path: `/usuariodetalhe/${usuario.id}` });
    },
    confirmDeleteUsuario(usuario) {
      this.usuario = usuario;
      this.deleteUsuarioDialog = true;
    },
    deleteUsuario() {
      this.usuarios = this.usuarios.filter(val => val.id !== this.usuario.id);
      this.deleteUsuarioDialog = false;
      this.usuario = {};
      this.$toast.add({severity:'success', summary: 'Successo', detail: 'Usuário Deletado', life: 3000});
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.usuarios.length; i++) {
        if (this.usuarios[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for ( var i = 0; i < 5; i++ ) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteUsuariosDialog = true;
    },
    deleteSelectedUsuarios() {
      this.usuarios = this.usuarios.filter(val => !this.selectedUsuarios.includes(val));
      this.deleteUsuariosDialog = false;
      this.selectedUsuarios = null;
      this.$toast.add({severity:'success', summary: 'Successo', detail: 'Usuários Deletados', life: 3000});
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }

      this.filters1 = {

        'id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'cidade': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'estado': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'tipo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'status': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},



        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'country.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'representative': {value: null, matchMode: FilterMatchMode.IN},
        'category': {value: null, matchMode: FilterMatchMode.IN},
        'price': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
      }

    },

    clearFilter1() {
      this.initFilters();
    },

    formatDate(value) {
      return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
