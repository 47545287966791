import api from "@/axios";
import axios from 'axios'


export default class UsuarioService {

    getssusuarioindex() {
        return api.get('api/ssusuarioindex' ).then((res) => res.data);
    }

    getssusuario(id) {
        const params = { id }
        return api.get('api/ssusuarioget', {params}).then((res) => res.data);
    }

    savessusuario(usuario) {
        return api.post('api/ssusuariosave', {usuario} ).then((res) => res.data);
    }


    deletessusuario(id) {
        const params = { id }
        return api.get('api/ssusuariodel', { params }).then((res) => res.data);
    }


    getClientes() {
        return fetch('demo/data/customers-small.json').then(res => res.json()).then(d => d.data);
        //return api.get('testarapi' ).then((res) => res.data);
    }

    getPesqCidade(params){
        // console.log(baseURLPython)
        return api.get('api/sspesqcid', {params}).then((res) => res.data);
    }

    getPesquisaCEP(cep) {
        return axios.get('https://viacep.com.br/ws/' + cep + '/json/', ).then((res) => res.data);
    }


    getProductsSmall() {
        return fetch('demo/data/products-small.json').then(res => res.json()).then(d => d.data);
    }

    getProductsMixed() {
        return fetch('demo/data/products-mixed.json').then(res => res.json()).then(d => d.data);
    }

    getProductsWithOrdersSmall() {
        return fetch('demo/data/products-orders-small.json').then(res => res.json()).then(d => d.data);
    }


    getLeads() {
        return fetch('demo/data/leads.json').then(res => res.json()).then(d => d.data);
    }

    getOrcamentos() {
        return fetch('demo/data/orcamentos.json').then(res => res.json()).then(d => d.data);
    }

    getPedidos() {
        return fetch('demo/data/pedidos.json').then(res => res.json()).then(d => d.data);
    }

    getObras() {
        return fetch('demo/data/obras.json').then(res => res.json()).then(d => d.data);
    }

    getProjetos() {
        return fetch('demo/data/projetos.json').then(res => res.json()).then(d => d.data);
    }




}
